import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import classnames from 'classnames';
import chevronDown from 'assets/img/chevron-down.svg';
import uuidv4 from 'uuid/v4';

class SelectInput extends Component {
  componentWillUnmount = () => {
    if (!this.props.disableReset) this.props.resetAction();
  };

  mapOptions = options => {
    let mappedOptions = options;
    if (typeof options[0] !== 'object') {
      mappedOptions = options.map(option => ({
        name: option,
        value: option
      }));

      if (this.props.showPlaceholder) {
        mappedOptions.unshift({ name: 'Select Option', value: '' });
      }
    } else {
      if (
        this.props.showPlaceholder &&
        this.props.placeholderText &&
        mappedOptions[0].name !== this.props.placeholderText
      ) {
        mappedOptions.unshift({ name: this.props.placeholderText, value: '' });
      }
    }
    mappedOptions = this.props.noSort ? mappedOptions : mappedOptions.sort((a, b) => (a.name > b.name) ? 1 : -1) ;
    return mappedOptions;
  };

  render = () => {
    const {
      disabled,
      options,
      overrideStyles,
      setAction,
      value,
      label,
      fillSpace
    } = this.props;
    return (
      <div
        className={classnames(
          !fillSpace ? styles.defaultStyles : styles.fullStyle,
          overrideStyles
        )}
      >
        <div
          className={classnames(styles.selectContainer, {
            [styles.disabled]: disabled
          })}
        >
          <select
            className={styles.selectBox}
            disabled={disabled}
            onChange={event => setAction(event.target.value)}
            value={value}
            aria-label={label || 'Select'}
          >
            {this.mapOptions(options).map(option => (
              <option key={uuidv4(option.name)} value={option.value}>
                {option.name}
              </option>
            ))}
          </select>
          <img
            className={styles.selectArrow}
            alt="select-arrow"
            src={chevronDown}
          />
        </div>
      </div>
    );
  };
}

SelectInput.propTypes = {
  disabled: PropTypes.bool,
  disableReset: PropTypes.bool,
  showPlaceholder: PropTypes.bool,
  placeholderText: PropTypes.string,
  options: PropTypes.oneOfType([PropTypes.array.isRequired]),
  overrideStyles: PropTypes.string,
  resetAction: PropTypes.func.isRequired,
  setAction: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.number.isRequired,
    PropTypes.bool.isRequired
  ]),
  label: PropTypes.string,
  fillSpace: PropTypes.bool
};

SelectInput.defaultProps = { showPlaceholder: true };

export default SelectInput;
