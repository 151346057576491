import React from 'react';
import PropTypes from 'prop-types';
import styles from './styles.module.css';
import checkedImg from 'assets/img/inputs/radio-checked.svg';
import uncheckedImg from 'assets/img/inputs/radio-unchecked.svg';
import checkedDisabledImg from 'assets/img/inputs/radio-checked-disabled.svg';
import uncheckedDisabledImg from 'assets/img/inputs/radio-unchecked-disabled.svg';
import uuidv4 from 'uuid/v4';

const getSvg = (checked, disabled) => {
  if (checked && !disabled) {
    return checkedImg;
  } else if (checked && disabled) {
    return checkedDisabledImg;
  } else if (!checked && !disabled) {
    return uncheckedImg;
  } else if (!checked && disabled) {
    return uncheckedDisabledImg;
  }
};

const RadioInput = props => {
  const {
    disabled,
    options,
    overrideStyles,
    setAction,
    value,
  } = props;
  const checkedOption = value;
  return (
    <div className={overrideStyles}>
      {options.map(option => {
        const checked = option.value === checkedOption;
       return (
          <label key={uuidv4(option)} className={styles.radioOption} >
            <input
              className={styles.radio}
              disabled={disabled}
              defaultChecked={checked}
              name={option.name}
              onChange={() => setAction(option.value)}
              type="radio"
              value={value}
            />
            <img
              className={styles.radioImg}
              src={getSvg(checked, disabled)}
              alt={checked ? 'checked' : 'unchecked'}
            />
            {option.name}
          </label>
        );
      })}
    </div>
  );
};

RadioInput.propTypes = {
  disabled: PropTypes.bool,
  overrideStyles: PropTypes.string,
  resetAction: PropTypes.func,
  setAction: PropTypes.func.isRequired
};

export default RadioInput;
