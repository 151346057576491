import React from 'react';
import { BsClock, BsClockFill } from "react-icons/bs";
import { FaCheckCircle } from "react-icons/fa";


export const stepActivePR = (status, statusSteps) => {
  for (let i = 0; i < statusSteps.length; i++) {
    if (statusSteps[i].includes(status) || statusSteps[i] === status || status.includes(statusSteps[i])) {
      return i;
    }
  }
};

export const iconAdded = (current, Active, styles, statusSteps, lastStepIgnore) => {

  if (current < Active) return <FaCheckCircle className={styles.colorGreen} />
  else if (current === Active &&  lastStepIgnore && current !== statusSteps.length - 1) return <BsClockFill className={styles.colorYellow} />;
  else if (current === Active && lastStepIgnore && current === statusSteps.length - 1) return <BsClockFill className={styles.colorYellow} />;
  else if (current === Active && current === statusSteps.length - 1) return <FaCheckCircle className={styles.colorGreen} />;
  else if(!lastStepIgnore) return <FaCheckCircle className={styles.colorGreen} />;
  return <BsClock />;
};