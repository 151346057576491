import React from 'react';
import { textFilter } from 'utils/textFilter.js';
import { getReasonsCodes } from 'utils/admin.js';
import moment from 'moment';
import Info from 'assets/img/info.svg';
import Popover from 'components/common/Popover';

// Default Sorting for the Admin Tool Tables
export const defaultSorted = [
  {
    id: 'name',
    desc: false,
  },
  {
    id: 'title',
    desc: false,
  },
];

//Style for icon
export const downtimeReasonIcon = {
  paddingLeft: 70,
};

//style for span
export const reasonCodeDisplay = {
  display:'flex'
}

// Mapping Admin Tool Values
export const adminToolValues = {
  users: 'users',
  taskTypes: 'taskTypes',
  downtimeCodes: 'downtimeCodes',
  roles:'roles'
};

// List of Admin Tools
export const adminTools = [
  adminToolValues.users,
  adminToolValues.taskTypes,
  adminToolValues.downtimeCodes,
  adminToolValues.roles
];

//Columns for dt code delegation Page
const downtimeCodesColumns = [
  {
    Header: 'Exception Category',
    accessor: 'downtimeType',
    minWidth: 100,
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
    filterable: true,
   filterMethod: (filter, row) => {
      return textFilter(filter, row);
    }
  },
  {
    Header: 'Exception Sub-Category',
    accessor: 'exceptionTypeName',
    minWidth: 150,
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
    filterable: false,
    filterMethod: (filter, row) => {
      return textFilter(filter, row);
    },
  },
  {
    Header: 'Exception Description',
    sortable: false,
    minWidth: 100,
    Cell: props => (
      <span style={downtimeReasonIcon}>
        <Popover text={props.original.exceptionDescription}>
          <img src={Info} alt={props.original.exceptionDescription} />
        </Popover>
      </span>
    ),
    headerClassName: 'columnHeader',
  },
  {
    Header: 'Reason Codes',
    id: 'reasonCode',
    accessor: data => {
      let output = [];
      let outputStr = '';
      outputStr = getReasonsCodes(data,output);
      return (
        <span style={reasonCodeDisplay}>
          <Popover text={outputStr}>{outputStr}</Popover>
        </span>
      );
    },
    minWidth: 300,
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
    filterable: false,
    filterMethod: (filter, row) => {
      return textFilter(filter, row);
    },
  },
];

// Columns for the Controls(TaskType) Admin Tool
const taskTypeColumns = [
  {
    Header: 'Task Name',
    accessor: 'title',
    minWidth: 150,
    headerClassName: 'columnHeader',
    defaultSortDesc: true,
    filterable: true,
    filterMethod: (filter, row) => {
      return textFilter(filter, row);
    },
  },
];

// Columns for the User Admin Tool
const userColumns = [
  {
    Header: 'Last Name',
    accessor: 'lastName',
    headerClassName: 'columnHeader',
    minWidth: 150,
    filterable: true,
    filterMethod: (filter, row) => {
      return textFilter(filter, row);
    },
    defaultSortDesc: true,
  },
  {
    Header: 'First Name',
    accessor: 'firstName',
    headerClassName: 'columnHeader',
    minWidth: 120,
  },
  {
    Header: 'Email',
    accessor: 'email',
    headerClassName: 'columnHeader',
    minWidth: 200,
    Cell: props => (
      <a href={`mailto:${props.value}`} className={'rt-email'}>
        {props.value}
      </a>
    ),
    className: 'rt-email',
  },
  {
    Header: 'Role',
    accessor: 'roleTitle',
    headerClassName: 'columnHeader',
    minWidth: 150,
  },
  {
    Header: 'Last log in date',
    accessor: 'lastLoginDate',
    headerClassName: 'centeredColumnHeader',
    minWidth: 120,
    Cell: props => (
      <div className={'centeredColAdmin'}>
        {props.original.lastLoginDate
          ? moment(props.original.lastLoginDate).format('MM/DD/YYYY')
          : ''}
      </div>
    ),
    sortable: true,
  },
  {
    Header: 'Days since last log in',
    accessor: 'daysSinceLastLogin',
    minWidth: 100,
    Cell: props => (
      <div className={'centeredColAdmin'}>
        {props.original.daysSinceLastLogin}
      </div>
    ),
    headerClassName: 'centeredColumnHeader',
    sortable: true,
  },
];

const roleColumns = [
  {
    Header: 'Role Title',
    accessor: 'title',
    headerClassName: 'columnHeader',
    minWidth: 150,
    filterable: true,
    filterMethod: (filter, row) => {
      return textFilter(filter, row);
    },
    defaultSortDesc: true,
  },
  {
    Header: 'Role Category',
    accessor: 'roleCategory',
    headerClassName: 'columnHeader',
    minWidth: 150,
    defaultSortDesc: true,
  },
]
// Exported Admin Config used in Admin Tools
export const adminConfig = {
  taskTypes: {
    title: 'controls',
    columns: taskTypeColumns,
    type: 'task type',
    id: 'taskTypeId',
  },
  users: {
    title: 'users',
    columns: userColumns,
    type: 'user',
    id: 'userId',
  },
  downtimeCodes: {
    title: 'DT code delegation',
    columns: downtimeCodesColumns,
    type: 'downtimeCodes',
    id: 'downtimeCodesId',
  },
  roles: {
    title: 'roles',
    columns: roleColumns,
    type: 'role',
    id: 'roleId', 
  }
};


// Constants for the Frequency of TaskTypes
export const frequencyConstants = {
  Weekly: 'weekly',
  Monthly: 'monthly',
  BiMonthly: 'bi-monthly',
  Quarterly: 'quarterly',
  SemiAnnually: 'semi-annually',
  Yearly: 'yearly',
  Biweekly: 'bi-weekly',
  OneTime: 'one-time',
};

// Options for the Frequency Dropdown on the "edit task" modal.
export const frequencyOptions = [
  {
    name: 'Weekly',
    value: frequencyConstants.Weekly,
  },
  {
    name: 'Monthly',
    value: frequencyConstants.Monthly,
  },
  {
    name: 'Quarterly',
    value: frequencyConstants.Quarterly,
  },
  {
    name: 'Semi-Annually',
    value: frequencyConstants.SemiAnnually,
  },
  {
    name: 'Yearly',
    value: frequencyConstants.Yearly,
  },
  {
    name: 'Bi-Weekly',
    value: frequencyConstants.Biweekly,
  },
  {
    name: 'Bi-Monthly',
    value: frequencyConstants.BiMonthly,
  },
  {
    name: 'One Time',
    value: frequencyConstants.OneTime,
  },
];

// ToolTip text for the roles
export const tooltipConstants ={
  tooltipGuestUser: "controls - read only\n production management - read only\n exception management - read only",
  tooltipReadonlyUser:"production management - read only\n exception management - read only",
  tooltipSupervisorUser: "controls - read/write\n production management - read/write\n exception management - read/write",
  tooltipSuperitendentUser: "controls - read/write\n Direct report controls - read/write\n production management - read only \n exception management - read/write",  
}
